import React, { useState } from 'react'
import { Plus, Search } from 'lucide-react'
import ContactList from '../components/ContactList'
import ContactForm from '../components/ContactForm'

const Contacts = () => {
  const [showForm, setShowForm] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold">Contacts</h1>
        <button
          onClick={() => setShowForm(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <Plus size={20} className="mr-2" />
          Add Contact
        </button>
      </div>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search contacts..."
          className="w-full p-2 pl-10 border rounded"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
      </div>
      <ContactList searchTerm={searchTerm} />
      {showForm && <ContactForm onClose={() => setShowForm(false)} />}
    </div>
  )
}

export default Contacts