import React from 'react'
import { Calendar, Plus, Video, Phone } from 'lucide-react'

const MeetingsSection = () => {
  const meetings = [
    { id: 1, title: 'Product Demo', time: '2:00 PM', type: 'video' },
    { id: 2, title: 'Client Call', time: '3:30 PM', type: 'phone' },
  ]

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="bg-green-600 text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <Calendar className="mr-2" size={20} />
          <h3 className="text-lg font-semibold">Your Meetings</h3>
        </div>
        <button className="bg-green-500 hover:bg-green-400 text-white px-3 py-1 rounded-md flex items-center transition-colors duration-300">
          <Plus size={16} className="mr-1" />
          Create Meeting
        </button>
      </div>
      <div className="p-4">
        {meetings.length > 0 ? (
          meetings.map(meeting => (
            <div key={meeting.id} className="flex items-center justify-between mb-4 last:mb-0">
              <div>
                <p className="font-medium">{meeting.title}</p>
                <p className="text-sm text-gray-500">{meeting.time}</p>
              </div>
              {meeting.type === 'video' ? (
                <Video className="text-green-500" size={20} />
              ) : (
                <Phone className="text-blue-500" size={20} />
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">No upcoming meetings</p>
        )}
      </div>
    </div>
  )
}

export default MeetingsSection