import React from 'react'
import { User, Mail, Phone, Building } from 'lucide-react'

const dummyContacts = [
  { id: 1, name: 'John Doe', email: 'john@example.com', phone: '123-456-7890', company: 'ABC Corp', jobTitle: 'Manager' },
  { id: 2, name: 'Jane Smith', email: 'jane@example.com', phone: '098-765-4321', company: 'XYZ Inc', jobTitle: 'Developer' },
]

const ContactList = ({ searchTerm }: { searchTerm: string }) => {
  const filteredContacts = dummyContacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.email.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {filteredContacts.map(contact => (
        <div key={contact.id} className="bg-white p-4 rounded shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <User className="mr-2" size={20} />
            {contact.name}
          </h3>
          <p className="text-gray-600 mb-1 flex items-center">
            <Mail className="mr-2" size={16} />
            {contact.email}
          </p>
          <p className="text-gray-600 mb-1 flex items-center">
            <Phone className="mr-2" size={16} />
            {contact.phone}
          </p>
          <p className="text-gray-600 flex items-center">
            <Building className="mr-2" size={16} />
            {contact.company} - {contact.jobTitle}
          </p>
        </div>
      ))}
    </div>
  )
}

export default ContactList