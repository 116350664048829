import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getLeadById, updateLead, getNotesForLead, addNoteToLead } from '../services/databaseService';
import { Lead } from '../types/Lead';
import { Note } from '../services/databaseService';
import { User, Mail, Phone, Building, DollarSign, Calendar, Edit2, Save, X, Plus, ChevronLeft } from 'lucide-react';
import NoteForm from '../components/NoteForm';

const LeadProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [lead, setLead] = useState<Lead | null>(null);
  const [editedLead, setEditedLead] = useState<Lead | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [showNoteForm, setShowNoteForm] = useState(false);

  useEffect(() => {
    const fetchLead = async () => {
      if (!id) return;
      setLoading(true);
      try {
        const [fetchedLead, fetchedNotes] = await Promise.all([
          getLeadById(parseInt(id)),
          getNotesForLead(parseInt(id))
        ]);
        setLead(fetchedLead);
        setEditedLead(fetchedLead);
        setNotes(fetchedNotes);
      } catch (err) {
        setError('Failed to fetch lead details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchLead();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedLead(prev => prev ? { ...prev, [name]: value } : null);
  };

  const handleSave = async () => {
    if (!editedLead || !id) return;
    try {
      const updatedLead = await updateLead(parseInt(id), editedLead);
      if (updatedLead) {
        setLead(updatedLead);
        setIsEditing(false);
      }
    } catch (err) {
      setError('Failed to update lead. Please try again.');
    }
  };

  const handleAddNote = async (content: string) => {
    if (!id) return;
    try {
      const newNote = await addNoteToLead(parseInt(id), content);
      if (newNote) {
        setNotes(prevNotes => [newNote, ...prevNotes]);
        setShowNoteForm(false);
      }
    } catch (err) {
      setError('Failed to add note. Please try again.');
    }
  };

  if (loading) return <div className="p-6">Loading...</div>;
  if (error) return <div className="p-6 text-red-500">{error}</div>;
  if (!lead) return <div className="p-6">Lead not found.</div>;

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <Link to="/leads" className="text-blue-500 hover:text-blue-600 flex items-center mb-4">
        <ChevronLeft size={20} className="mr-1" />
        Back to Leads
      </Link>
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Lead Information Section */}
        <div className="w-full lg:w-1/2">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">{lead.name}</h1>
                {isEditing ? (
                  <div>
                    <button onClick={handleSave} className="bg-green-500 hover:bg-green-600 text-white p-2 rounded-full mr-2 transition duration-200">
                      <Save size={20} />
                    </button>
                    <button onClick={() => setIsEditing(false)} className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-full transition duration-200">
                      <X size={20} />
                    </button>
                  </div>
                ) : (
                  <button onClick={() => setIsEditing(true)} className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full transition duration-200">
                    <Edit2 size={20} />
                  </button>
                )}
              </div>
              <div className="space-y-4">
                <LeadField
                  icon={<Mail className="mr-2" />}
                  label="Email"
                  value={lead.email}
                  isEditing={isEditing}
                  name="email"
                  onChange={handleInputChange}
                  editedValue={editedLead?.email}
                />
                <LeadField
                  icon={<Phone className="mr-2" />}
                  label="Phone"
                  value={lead.phone}
                  isEditing={isEditing}
                  name="phone"
                  onChange={handleInputChange}
                  editedValue={editedLead?.phone}
                />
                <LeadField
                  icon={<Building className="mr-2" />}
                  label="Company"
                  value={lead.company}
                  isEditing={isEditing}
                  name="company"
                  onChange={handleInputChange}
                  editedValue={editedLead?.company}
                />
                <LeadField
                  icon={<DollarSign className="mr-2" />}
                  label="Value"
                  value={`$${lead.value}`}
                  isEditing={isEditing}
                  name="value"
                  onChange={handleInputChange}
                  editedValue={editedLead?.value.toString()}
                  type="number"
                />
                <LeadField
                  icon={<User className="mr-2" />}
                  label="Status"
                  value={lead.status}
                  isEditing={isEditing}
                  name="status"
                  onChange={handleInputChange}
                  editedValue={editedLead?.status}
                />
                <div className="flex items-center">
                  <Calendar className="mr-2" />
                  <span className="font-semibold mr-2">Created:</span>
                  <span>{new Date(lead.created_at).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Notes Section */}
        <div className="w-full lg:w-1/2">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Notes</h2>
                <button
                  onClick={() => setShowNoteForm(true)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-200"
                >
                  <Plus size={20} className="mr-2" />
                  Add Note
                </button>
              </div>
              {showNoteForm && (
                <NoteForm onSubmit={handleAddNote} onCancel={() => setShowNoteForm(false)} />
              )}
              {notes.length > 0 ? (
                <div className="space-y-4 mt-4 max-h-[calc(100vh-300px)] overflow-y-auto">
                  {notes.map(note => (
                    <div key={note.id} className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-gray-700 whitespace-pre-wrap">{note.content}</p>
                      <p className="text-sm text-gray-500 mt-2">
                        {new Date(note.created_at).toLocaleString()}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No notes available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface LeadFieldProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
  isEditing: boolean;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editedValue?: string;
  type?: string;
}

const LeadField: React.FC<LeadFieldProps> = ({ icon, label, value, isEditing, name, onChange, editedValue, type = "text" }) => (
  <div className="flex items-center">
    {icon}
    <span className="font-semibold mr-2">{label}:</span>
    {isEditing ? (
      <input
        type={type}
        name={name}
        value={editedValue}
        onChange={onChange}
        className="border rounded px-2 py-1 w-full"
      />
    ) : (
      <span>{value}</span>
    )}
  </div>
);

export default LeadProfile;