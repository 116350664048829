import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend)

const Analytics = () => {
  const pipelineData = {
    labels: ['New Lead', 'Follow-Up', 'Negotiation', 'Closed'],
    datasets: [
      {
        label: 'Number of Leads',
        data: [12, 19, 3, 5],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
    ],
  }

  const conversionData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Conversion Rate',
        data: [0.1, 0.15, 0.2, 0.18, 0.22, 0.25],
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  }

  return (
    <div className="p-6">
      <h1 className="text-3xl font-semibold mb-6">Analytics</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Pipeline Overview</h2>
          <Bar data={pipelineData} />
        </div>
        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Conversion Rate Trend</h2>
          <Line data={conversionData} />
        </div>
      </div>
    </div>
  )
}

export default Analytics