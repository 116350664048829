// This is a mock service for Twilio functionality
// In a real application, these calls would be made to a backend server

interface Conversation {
  id: string;
  contact: string;
  lastMessage: string;
  timestamp: Date;
  unread: boolean;
}

interface Message {
  id: string;
  sender: 'user' | 'contact';
  content: string;
  timestamp: Date;
}

const mockConversations: Conversation[] = [
  {
    id: '1',
    contact: 'John Doe',
    lastMessage: 'Hey, how are you?',
    timestamp: new Date('2023-05-10T10:30:00'),
    unread: true,
  },
  {
    id: '2',
    contact: 'Jane Smith',
    lastMessage: 'Can we schedule a meeting?',
    timestamp: new Date('2023-05-09T15:45:00'),
    unread: false,
  },
];

const mockMessages: { [key: string]: Message[] } = {
  '1': [
    {
      id: '1',
      sender: 'contact',
      content: 'Hey, how are you?',
      timestamp: new Date('2023-05-10T10:30:00'),
    },
    {
      id: '2',
      sender: 'user',
      content: "I'm doing well, thanks! How about you?",
      timestamp: new Date('2023-05-10T10:35:00'),
    },
  ],
  '2': [
    {
      id: '1',
      sender: 'contact',
      content: 'Can we schedule a meeting?',
      timestamp: new Date('2023-05-09T15:45:00'),
    },
    {
      id: '2',
      sender: 'user',
      content: 'Sure, how about tomorrow at 2 PM?',
      timestamp: new Date('2023-05-09T16:00:00'),
    },
  ],
};

export const getConversations = async (): Promise<Conversation[]> => {
  // Simulate API call
  return new Promise(resolve => setTimeout(() => resolve(mockConversations), 500));
};

export const getMessages = async (conversationId: string): Promise<Message[]> => {
  // Simulate API call
  return new Promise(resolve => setTimeout(() => resolve(mockMessages[conversationId] || []), 500));
};

export const sendMessage = async (conversationId: string, content: string): Promise<Message> => {
  // Simulate API call
  const newMessage: Message = {
    id: Date.now().toString(),
    sender: 'user',
    content,
    timestamp: new Date(),
  };

  mockMessages[conversationId] = [...(mockMessages[conversationId] || []), newMessage];

  return new Promise(resolve => setTimeout(() => resolve(newMessage), 500));
};

export const sendSMS = async (to: string, body: string) => {
  console.log(`Sending SMS to ${to}: ${body}`);
  // Simulate API call
  return new Promise(resolve => setTimeout(() => resolve({ sid: 'mock_sid' }), 500));
};

export const createConversation = async (customerPhone: string) => {
  console.log(`Creating conversation for ${customerPhone}`);
  // Simulate API call
  return new Promise(resolve => setTimeout(() => resolve({ sid: 'mock_conversation_sid' }), 500));
};

export const sendMessageToConversation = async (conversationSid: string, body: string, author: string) => {
  console.log(`Sending message to conversation ${conversationSid} from ${author}: ${body}`);
  // Simulate API call
  return new Promise(resolve => setTimeout(() => resolve({ sid: 'mock_message_sid' }), 500));
};