import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import TopNav from './components/TopNav'
import Dashboard from './pages/Dashboard'
import Leads from './pages/Leads'
import LeadProfile from './pages/LeadProfile'
import Contacts from './pages/Contacts'
import LeadPipeline from './pages/LeadPipeline'
import Conversations from './pages/Conversations'
import AIAgents from './pages/AIAgents'
import Analytics from './pages/Analytics'
import Settings from './pages/Settings'
import Auth from './pages/Auth'
import { getCurrentUser, logoutUser } from './services/authService'

function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const checkAuth = async () => {
      const currentUser = await getCurrentUser()
      setIsAuthenticated(!!currentUser)
      setUser(currentUser)
    }
    checkAuth()
  }, [])

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  const handleLogout = async () => {
    await logoutUser()
    setIsAuthenticated(false)
    setUser(null)
  }

  return (
    <Router>
      <div className="flex h-screen bg-gray-100">
        {isAuthenticated && <Sidebar isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />}
        <div className="flex flex-col flex-1 overflow-hidden">
          {isAuthenticated && <TopNav user={user} onLogout={handleLogout} />}
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
            <Routes>
              <Route path="/auth" element={<Auth />} />
              <Route
                path="/"
                element={isAuthenticated ? <Dashboard /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/leads"
                element={isAuthenticated ? <Leads /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/leads/:id"
                element={isAuthenticated ? <LeadProfile /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/contacts"
                element={isAuthenticated ? <Contacts /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/pipeline"
                element={isAuthenticated ? <LeadPipeline /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/conversations"
                element={isAuthenticated ? <Conversations /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/ai-agents"
                element={isAuthenticated ? <AIAgents /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/analytics"
                element={isAuthenticated ? <Analytics /> : <Navigate to="/auth" replace />}
              />
              <Route
                path="/settings"
                element={isAuthenticated ? <Settings /> : <Navigate to="/auth" replace />}
              />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  )
}

export default App