import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LayoutDashboard, Inbox, Users, GitBranch, MessageCircle, Bot, Settings, ChevronLeft, ChevronRight, ChevronDown, ChevronUp } from 'lucide-react'

interface NavItem {
  to: string;
  icon: React.ReactNode;
  text: string;
  badge?: string;
}

interface NavCategory {
  name: string;
  icon: React.ReactNode;
  items: NavItem[];
}

interface SidebarProps {
  isCollapsed: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, toggleSidebar }) => {
  const [expandedCategories, setExpandedCategories] = useState<string[]>(['Main'])
  const location = useLocation()

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    )
  }

  const navCategories: NavCategory[] = [
    {
      name: 'Main',
      icon: <LayoutDashboard size={20} />,
      items: [
        { to: "/", icon: <LayoutDashboard size={20} />, text: "Dashboard" },
        { to: "/inbox", icon: <Inbox size={20} />, text: "Inbox", badge: "Soon" },
      ]
    },
    {
      name: 'Sales',
      icon: <Users size={20} />,
      items: [
        { to: "/leads", icon: <Users size={20} />, text: "Leads" },
        { to: "/pipeline", icon: <GitBranch size={20} />, text: "Pipeline" },
        { to: "/conversations", icon: <MessageCircle size={20} />, text: "Conversations" },
      ]
    },
    {
      name: 'AI Agents',
      icon: <Bot size={20} />,
      items: [
        { to: "/ai-agents", icon: <Bot size={20} />, text: "AI Agents" },
      ]
    },
  ]

  return (
    <div className={`bg-[#1e1e1e] text-gray-300 ${isCollapsed ? 'w-20' : 'w-64'} flex flex-col h-screen transition-all duration-300 ease-in-out`}>
      <div className={`flex items-center p-4 ${isCollapsed ? 'justify-center' : 'justify-between'} border-b border-gray-700`}>
        {!isCollapsed && (
          <div className="flex items-center">
            <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center mr-3">
              <span className="text-2xl font-bold text-white">Z</span>
            </div>
            <div>
              <h2 className="text-lg font-semibold text-white">Jacob Bonofiglio</h2>
              <p className="text-xs text-gray-400">Zeus Marketing</p>
            </div>
          </div>
        )}
        <button
          onClick={toggleSidebar}
          className={`text-gray-400 hover:bg-gray-700 hover:text-white p-2 rounded-full transition-colors duration-200 ${isCollapsed ? 'ml-2' : ''}`}
        >
          {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
        </button>
      </div>
      <nav className="flex-grow overflow-y-auto py-4">
        {navCategories.map((category) => (
          <div key={category.name} className="mb-2">
            {!isCollapsed && (
              <button
                onClick={() => toggleCategory(category.name)}
                className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white transition-colors duration-200"
              >
                <div className="flex items-center">
                  {category.icon}
                  <span className="ml-3">{category.name}</span>
                </div>
                {category.items.length > 0 && (
                  expandedCategories.includes(category.name) ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                )}
              </button>
            )}
            {(isCollapsed || expandedCategories.includes(category.name)) && category.items.length > 0 && (
              <div className={`${isCollapsed ? '' : 'ml-4'}`}>
                {category.items.map((item) => (
                  <Link
                    key={item.to}
                    to={item.to}
                    className={`flex items-center py-2 px-4 rounded-lg transition duration-200 ${
                      location.pathname === item.to
                        ? 'bg-gray-800 text-white'
                        : 'hover:bg-gray-700 hover:text-white'
                    } ${isCollapsed ? 'justify-center' : ''} mb-1 mx-2`}
                  >
                    <span className={`${isCollapsed ? '' : 'mr-3'} text-xl`}>{item.icon}</span>
                    {!isCollapsed && (
                      <div className="flex justify-between items-center w-full">
                        <span className="text-sm">{item.text}</span>
                        {item.badge && (
                          <span className="bg-blue-500 text-white text-xs px-2 py-0.5 rounded-full">
                            {item.badge}
                          </span>
                        )}
                      </div>
                    )}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
      {!isCollapsed && (
        <div className="p-4 border-t border-gray-700">
          <Link to="/settings" className="flex items-center text-gray-400 hover:text-white transition-colors duration-200">
            <Settings size={20} className="mr-3" />
            <span className="text-sm">Settings</span>
          </Link>
        </div>
      )}
    </div>
  )
}

export default Sidebar