import React from 'react'

interface StatCardProps {
  title: string;
  value: string;
  icon: string;
  trend: React.ReactNode;
  trendValue: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon, trend, trendValue }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <div className="text-3xl">{icon}</div>
        <div className="flex items-center text-sm font-medium">
          {trend}
          <span className="ml-1">{trendValue}</span>
        </div>
      </div>
      <p className="text-gray-500 text-sm mb-1">{title}</p>
      <p className="text-2xl font-bold text-gray-800">{value}</p>
    </div>
  )
}

export default StatCard