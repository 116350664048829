import React from 'react'
import StatCard from '../components/StatCard'
import TasksSection from '../components/TasksSection'
import MeetingsSection from '../components/MeetingsSection'
import InvoicesSection from '../components/InvoicesSection'
import { TrendingUp, TrendingDown } from 'lucide-react'

const Dashboard = () => {
  const currentDate = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

  return (
    <div className="p-6 bg-gray-50">
      <div className="mb-8">
        <h1 className="text-4xl font-bold mb-2 text-gray-800">Hey Jacob, let's close some deals 💰</h1>
        <p className="text-gray-600 text-lg">{currentDate}</p>
      </div>

      <div className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-gray-700">2024 Stats</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
          <StatCard title="Your Leads" value="77" icon="👥" trend={<TrendingUp className="text-green-500" />} trendValue="+5%" />
          <StatCard title="Calls Made" value="135" icon="📞" trend={<TrendingUp className="text-green-500" />} trendValue="+12%" />
          <StatCard title="Leads Closed" value="6" icon="🤝" trend={<TrendingDown className="text-red-500" />} trendValue="-2%" />
          <StatCard title="Closed Deal Value" value="$10,875" icon="💰" trend={<TrendingUp className="text-green-500" />} trendValue="+8%" />
          <StatCard title="Total Deal Value" value="$52,321" icon="💵" trend={<TrendingUp className="text-green-500" />} trendValue="+15%" />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
        <TasksSection />
        <MeetingsSection />
        <InvoicesSection />
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">New Deals</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3 px-4 text-left">Deal Name</th>
                <th className="py-3 px-4 text-left">Company</th>
                <th className="py-3 px-4 text-left">Value</th>
                <th className="py-3 px-4 text-left">Stage</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="py-3 px-4">Software Upgrade</td>
                <td className="py-3 px-4">TechCorp Inc.</td>
                <td className="py-3 px-4">$15,000</td>
                <td className="py-3 px-4"><span className="bg-yellow-100 text-yellow-800 py-1 px-2 rounded-full text-sm">Negotiation</span></td>
              </tr>
              <tr className="border-b">
                <td className="py-3 px-4">Marketing Campaign</td>
                <td className="py-3 px-4">AdGenius Co.</td>
                <td className="py-3 px-4">$8,500</td>
                <td className="py-3 px-4"><span className="bg-green-100 text-green-800 py-1 px-2 rounded-full text-sm">Closed Won</span></td>
              </tr>
              <tr>
                <td className="py-3 px-4">Cloud Migration</td>
                <td className="py-3 px-4">DataSafe Solutions</td>
                <td className="py-3 px-4">$22,000</td>
                <td className="py-3 px-4"><span className="bg-blue-100 text-blue-800 py-1 px-2 rounded-full text-sm">Proposal</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Dashboard