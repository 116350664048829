import React, { useState } from 'react';
import { Settings as SettingsIcon, Users, Phone, Mail, Bell, FileText, CreditCard, GitBranch } from 'lucide-react';

const settingsOptions = [
  { id: 'pipeline', name: 'Pipeline', icon: <GitBranch size={20} /> },
  { id: 'team', name: 'Team management', icon: <Users size={20} /> },
  { id: 'twilio', name: 'Twilio', icon: <Phone size={20} /> },
  { id: 'email', name: 'Email Templates', icon: <Mail size={20} /> },
  { id: 'notifications', name: 'Lead notifications', icon: <Bell size={20} /> },
  { id: 'invoices', name: 'Invoices', icon: <FileText size={20} /> },
  { id: 'billing', name: 'Billing', icon: <CreditCard size={20} /> },
];

const Settings: React.FC = () => {
  const [activeOption, setActiveOption] = useState('pipeline');

  return (
    <div className="flex h-full bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <div className="p-4 border-b">
          <h2 className="text-xl font-semibold flex items-center">
            <SettingsIcon className="mr-2" size={24} />
            Settings
          </h2>
        </div>
        <nav className="mt-4">
          {settingsOptions.map((option) => (
            <button
              key={option.id}
              className={`w-full text-left px-4 py-2 flex items-center ${
                activeOption === option.id ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
              }`}
              onClick={() => setActiveOption(option.id)}
            >
              {React.cloneElement(option.icon, { className: 'mr-3' })}
              {option.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="flex-1 p-8">
        <h3 className="text-2xl font-semibold mb-4">
          {settingsOptions.find(option => option.id === activeOption)?.name}
        </h3>
        <p className="text-gray-600">
          Configure your {settingsOptions.find(option => option.id === activeOption)?.name.toLowerCase()} settings here.
        </p>
        {/* Add specific settings forms/options for each section here */}
      </div>
    </div>
  );
};

export default Settings;