import React from 'react';

const AIAgents: React.FC = () => {
  return (
    <div className="p-6 bg-gray-50">
      <h1 className="text-3xl font-bold mb-4">AI Agents</h1>
      <p>This page is under construction. AI Agents functionality will be added soon.</p>
    </div>
  );
};

export default AIAgents;