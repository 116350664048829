import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Users, MoreHorizontal, TrendingUp, TrendingDown, Plus, Filter, RefreshCw, Play, Pause, Settings, ChevronDown } from 'lucide-react';

interface Lead {
  id: string;
  name: string;
  value: number;
  trend: 'up' | 'down';
  trendValue: number;
}

interface Column {
  id: string;
  title: string;
  leadIds: string[];
}

interface PipelineState {
  leads: { [key: string]: Lead };
  columns: { [key: string]: Column };
  columnOrder: string[];
}

const initialData: PipelineState = {
  leads: {
    'lead-1': { id: 'lead-1', name: 'Constanza Mariano', value: 50000, trend: 'up', trendValue: 5 },
    'lead-2': { id: 'lead-2', name: 'Helene Demontpion', value: 75000, trend: 'down', trendValue: 2 },
    'lead-3': { id: 'lead-3', name: 'Eryn Hedgecock', value: 100000, trend: 'up', trendValue: 8 },
    'lead-4': { id: 'lead-4', name: 'Ervin Hoagland', value: 60000, trend: 'up', trendValue: 3 },
    'lead-5': { id: 'lead-5', name: 'Steve Pinizzotto', value: 80000, trend: 'down', trendValue: 1 },
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Opportunities',
      leadIds: ['lead-1', 'lead-2'],
    },
    'column-2': {
      id: 'column-2',
      title: 'Review',
      leadIds: ['lead-3'],
    },
    'column-3': {
      id: 'column-3',
      title: 'Negotiation',
      leadIds: ['lead-4'],
    },
    'column-4': {
      id: 'column-4',
      title: 'Closed',
      leadIds: ['lead-5'],
    },
  },
  columnOrder: ['column-1', 'column-2', 'column-3', 'column-4'],
};

const LeadPipeline: React.FC = () => {
  const [state, setState] = useState(initialData);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      const newLeadIds = Array.from(start.leadIds);
      newLeadIds.splice(source.index, 1);
      newLeadIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        leadIds: newLeadIds,
      };

      setState(prevState => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          [newColumn.id]: newColumn,
        },
      }));
    } else {
      const startLeadIds = Array.from(start.leadIds);
      startLeadIds.splice(source.index, 1);
      const newStart = {
        ...start,
        leadIds: startLeadIds,
      };

      const finishLeadIds = Array.from(finish.leadIds);
      finishLeadIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        leadIds: finishLeadIds,
      };

      setState(prevState => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      }));
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-2">Rebound Pipeline</h1>
        <div className="flex justify-between items-center">
          <div className="flex space-x-2">
            <button className="bg-white px-3 py-1 rounded-full text-sm font-medium shadow-sm hover:shadow-md transition-shadow">
              All Leads
            </button>
            <button className="bg-white px-3 py-1 rounded-full text-sm font-medium shadow-sm hover:shadow-md transition-shadow">
              My Leads
            </button>
          </div>
          <div className="flex space-x-2">
            <button className="bg-white p-2 rounded-full shadow-sm hover:shadow-md transition-shadow">
              <Filter size={16} />
            </button>
            <button className="bg-white p-2 rounded-full shadow-sm hover:shadow-md transition-shadow">
              <RefreshCw size={16} />
            </button>
            <button className="bg-white p-2 rounded-full shadow-sm hover:shadow-md transition-shadow">
              <Play size={16} />
            </button>
            <button className="bg-white p-2 rounded-full shadow-sm hover:shadow-md transition-shadow">
              <Settings size={16} />
            </button>
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex space-x-4 overflow-x-auto pb-4">
          {state.columnOrder.map((columnId) => {
            const column = state.columns[columnId];
            const leads = column.leadIds.map((leadId) => state.leads[leadId]);

            return (
              <div key={column.id} className="flex-shrink-0 w-72">
                <h2 className="font-semibold mb-3">{column.title} ({leads.length})</h2>
                <Droppable droppableId={column.id}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`bg-gray-50 p-2 rounded-lg min-h-[500px] ${
                        snapshot.isDraggingOver ? 'bg-blue-50' : ''
                      }`}
                    >
                      {leads.map((lead, index) => (
                        <Draggable key={lead.id} draggableId={lead.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`bg-white p-4 mb-3 rounded-lg shadow-sm hover:shadow-md transition-shadow ${
                                snapshot.isDragging ? 'shadow-lg' : ''
                              }`}
                            >
                              <div className="flex items-center justify-between mb-2">
                                <div className="flex items-center">
                                  <div className="w-8 h-8 bg-gray-300 rounded-full mr-2"></div>
                                  <h3 className="font-medium">{lead.name}</h3>
                                </div>
                                <button className="text-gray-400 hover:text-gray-600">
                                  <MoreHorizontal size={16} />
                                </button>
                              </div>
                              <div className="flex items-center justify-between">
                                <span className="text-sm text-gray-500">${lead.value.toLocaleString()}</span>
                                <div className={`flex items-center ${lead.trend === 'up' ? 'text-green-500' : 'text-red-500'}`}>
                                  {lead.trend === 'up' ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
                                  <span className="ml-1 text-sm">{lead.trendValue}%</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
        </div>
      </DragDropContext>

      <button className="fixed bottom-6 right-6 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors">
        <Plus size={24} />
      </button>
    </div>
  );
};

export default LeadPipeline;