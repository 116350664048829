import React from 'react'
import { CheckSquare, Plus } from 'lucide-react'

const TasksSection = () => {
  const tasks = [
    { id: 1, title: 'Follow Up With Tyler Tyler', date: 'Sat, Mar 1, 2025 10:30 am', completed: false },
    { id: 2, title: 'Prepare Proposal for XYZ Corp', date: 'Sun, Mar 2, 2025 2:00 pm', completed: true },
    { id: 3, title: 'Client Meeting with ABC Inc', date: 'Mon, Mar 3, 2025 11:00 am', completed: false },
  ]

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="bg-indigo-600 text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <CheckSquare className="mr-2" size={20} />
          <h3 className="text-lg font-semibold">Your Tasks</h3>
        </div>
        <button className="bg-indigo-500 hover:bg-indigo-400 text-white px-3 py-1 rounded-md flex items-center transition-colors duration-300">
          <Plus size={16} className="mr-1" />
          Create Task
        </button>
      </div>
      <div className="p-4">
        {tasks.map(task => (
          <div key={task.id} className="flex items-center mb-4 last:mb-0">
            <input
              type="checkbox"
              checked={task.completed}
              onChange={() => {}}
              className="mr-3 form-checkbox h-5 w-5 text-indigo-600 rounded"
            />
            <div className={task.completed ? 'line-through text-gray-500' : ''}>
              <p className="font-medium">{task.title}</p>
              <p className="text-sm text-gray-500">{task.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TasksSection