import React from 'react'
import { DollarSign, Plus, FileText } from 'lucide-react'

const InvoicesSection = () => {
  const invoices = [
    { id: 1, client: 'TechCorp Inc.', amount: '$5,000', status: 'Paid' },
    { id: 2, client: 'AdGenius Co.', amount: '$3,500', status: 'Pending' },
  ]

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="bg-purple-600 text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <DollarSign className="mr-2" size={20} />
          <h3 className="text-lg font-semibold">Invoices</h3>
        </div>
        <button className="bg-purple-500 hover:bg-purple-400 text-white px-3 py-1 rounded-md flex items-center transition-colors duration-300">
          <Plus size={16} className="mr-1" />
          Create Invoice
        </button>
      </div>
      <div className="p-4">
        {invoices.length > 0 ? (
          invoices.map(invoice => (
            <div key={invoice.id} className="flex items-center justify-between mb-4 last:mb-0">
              <div className="flex items-center">
                <FileText className="mr-3 text-gray-400" size={20} />
                <div>
                  <p className="font-medium">{invoice.client}</p>
                  <p className="text-sm text-gray-500">{invoice.amount}</p>
                </div>
              </div>
              <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                invoice.status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
              }`}>
                {invoice.status}
              </span>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No recent invoices</p>
        )}
      </div>
    </div>
  )
}

export default InvoicesSection