import { supabase } from './supabaseClient'
import { Lead } from '../types/Lead'

export const getLeads = async (): Promise<Lead[]> => {
  const { data, error } = await supabase
    .from('leads')
    .select('*')
    .order('created_at', { ascending: false })

  if (error) {
    console.error('Error fetching leads:', error)
    return []
  }

  return data || []
}

export const getLeadById = async (id: number): Promise<Lead | null> => {
  const { data, error } = await supabase
    .from('leads')
    .select('*')
    .eq('id', id)
    .single()

  if (error) {
    console.error('Error fetching lead:', error)
    return null
  }

  return data
}

export const updateLead = async (id: number, lead: Partial<Lead>): Promise<Lead | null> => {
  const { data, error } = await supabase
    .from('leads')
    .update(lead)
    .eq('id', id)
    .select()
    .single()

  if (error) {
    console.error('Error updating lead:', error)
    return null
  }

  return data
}

export const addLead = async (lead: Omit<Lead, 'id' | 'created_at'>): Promise<Lead | null> => {
  const { data, error } = await supabase
    .from('leads')
    .insert(lead)
    .select()
    .single()

  if (error) {
    console.error('Error adding lead:', error)
    return null
  }

  return data
}

export interface Note {
  id: number;
  lead_id: number;
  content: string;
  created_at: string;
}

export const getNotesForLead = async (leadId: number): Promise<Note[]> => {
  const { data, error } = await supabase
    .from('lead_notes')
    .select('*')
    .eq('lead_id', leadId)
    .order('created_at', { ascending: false })

  if (error) {
    console.error('Error fetching notes:', error)
    return []
  }

  return data || []
}

export const addNoteToLead = async (leadId: number, content: string): Promise<Note | null> => {
  const { data, error } = await supabase
    .from('lead_notes')
    .insert({ lead_id: leadId, content })
    .select()
    .single()

  if (error) {
    console.error('Error adding note:', error)
    return null
  }

  return data
}