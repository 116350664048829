import React from 'react'
import { Search, Plus, Phone, Bell, LogOut } from 'lucide-react'

interface TopNavProps {
  user: any;
  onLogout: () => void;
}

const TopNav: React.FC<TopNavProps> = ({ user, onLogout }) => {
  return (
    <div className="bg-white shadow-md p-4">
      <div className="flex justify-between items-center">
        <div className="relative w-1/3">
          <input
            type="text"
            placeholder="Search here..."
            className="w-full p-2 pl-10 border rounded-md"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <div className="flex items-center space-x-4">
          <button className="bg-blue-500 text-white p-2 rounded-full">
            <Plus size={20} />
          </button>
          <button className="bg-gray-200 p-2 rounded-full">
            <Phone size={20} />
          </button>
          <button className="bg-gray-200 p-2 rounded-full">
            <Bell size={20} />
          </button>
          <div className="flex items-center space-x-2">
            <img src="https://i.pravatar.cc/40?img=8" alt="Profile" className="w-10 h-10 rounded-full" />
            <span className="font-medium">{user?.user_metadata?.name || user?.email}</span>
          </div>
          <button onClick={onLogout} className="bg-red-500 text-white p-2 rounded-full">
            <LogOut size={20} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TopNav